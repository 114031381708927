/**
 * Grab the first email info on a contact
 * @param contact
 */
import { VolunteerListInfo } from '@/views/Programs/Volunteers/ext/gridcolumntypes'
import { IndividualEmailInfo } from '@/GeneratedTypes/ListInfo/IndividualEmailInfo'
import { getEmptyIndividualEmailInfo } from '@/lib/support/models/ListInfo/IndividualEmailInfo/data'

/***
 * Grab first email or a blank address.
 * @param contact
 */
export function getFirstEmailInfo(contact: VolunteerListInfo): IndividualEmailInfo {
  return contact?.emailAddresses?.[0] || getEmptyIndividualEmailInfo()
}
