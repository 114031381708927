import { DataTableHeader } from 'vuetify/types'
const baseHeaders: DataTableHeader[] = [
  {
    text: 'Name',
    value: 'prettyName',
    sort: (a: string, b: string) => {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    },
  },
  { text: 'Gender', value: 'gender' },
  { text: '', value: 'actions', sortable: false },
]

export const cheerDirectorHeaders = headersWithInviteAndApproved()
export const coachHeaders = headersWithInviteAndApproved()
export const refHeaders = headersWithInviteAndApproved()
function headersWithInviteAndApproved() {
  const headers = [...baseHeaders]
  headers.splice(1, 0, { text: 'Approved', value: 'approved' })
  headers.splice(3, 0, { text: 'Invitaton Status', value: 'invitationStatus' })
  return headers
}

export const commissionerHeaders = headersWithInviteAndRoles()
function headersWithInviteAndRoles() {
  const headers = [...baseHeaders]
  headers.splice(-1, 0, { text: 'Invitaton Status', value: 'invitationStatus' })
  headers.splice(-1, 0, { text: 'Roles', value: 'roles_text' })
  return headers
}

export const othersHeaders = headersWithRoles()
function headersWithRoles() {
  const headers = [...baseHeaders]
  headers.splice(-1, 0, { text: 'Roles', value: 'roles_text' })
  return headers
}
