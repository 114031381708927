import { DataTableHeader } from 'vuetify/types'

export const volunteerSearchHeaders = [
  { text: 'Name', value: 'name' },
  { text: 'Gender', value: 'gender' },
] as DataTableHeader[]

export const volunteerSelectionHeaders = [
  { text: 'Name', value: 'name' },
  { text: 'Gender', value: 'gender' },
  { text: 'Address', value: 'firstAddress' },
] as DataTableHeader[]

export interface VolunteerSelectionGridInfo {
  /** how the name should be formatted for display **/
  name: string
  /** unique ID for the volunteer **/
  individualID: number
  /** additional field per requirements */
  gender: string
  /** firstName easy deconstructed name **/
  firstName: string
  /** lastName for a deconstructed name **/
  lastName: string
  /** provides for a selected, puts a check on the selected */
  selected: boolean
}
