import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'

export type VolunteerInfo = LeagueVolunteerInfo

export enum InvitationStatusEnum {
  EMPTY, //Both acceptInviteDate and expireInviteDate are empty strings (or nulls)
  SENT, //acceptInviteDate is an empty string and expireInviteDate has a value but the date has not passed yet
  ACCEPTED, //acceptInviteDate has a value
  EXPIRED, //acceptInviteDate is an empty string and expireInviteDate has a value AND the date has passed
}

export interface VolunteerListInfo extends VolunteerInfo {
  approved: boolean
  invitationStatus: InvitationStatusEnum
  roles_text: string
  prettyName: string
}
