





import { defineComponent, PropType, computed } from '@vue/composition-api'
import { InvitationStatusEnum, VolunteerListInfo } from '@/views/Programs/Volunteers/ext/gridcolumntypes'
export default defineComponent({
  name: 'InviteBtn',
  props: { data: { type: Object as PropType<VolunteerListInfo>, required: false } },
  components: {},
  setup(props, { emit }) {
    const canInvite = computed(() => {
      return props.data
        ? props.data.approved &&
            props.data.invitationStatus != InvitationStatusEnum.ACCEPTED &&
            props.data.inviteAllowed
        : false
    })
    function click() {
      emit('click')
    }
    return { click, canInvite }
  },
})
