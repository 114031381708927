import { Route } from 'vue-router'
import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { getEmptyLeagueVolunteer, LeagueVolunteerSeedInfo } from '@/lib/support/models/LeagueVolunteer/data'
import { leagueVolunteerInfoToLeagueVolunteer } from '@/lib/support/models/LeagueVolunteer/leagueVolunteerInfoToLeagueVolunteer'
import { newPlayerID } from '@/lib/support/models/LeaguePlayerInfo/data'

class VolunteerRouteRuntimeException extends RuntimeException {
  name = 'Volunteer Route Error'
}

/***
 * Takes the route name (edit,copy,new) and produces a volunteer model for initial edit.
 * @param routeName
 * @param currentVolunteer
 */

export function routeActionToVolunteer(
  routeName: string | null | undefined,
  currentVolunteer: LeagueVolunteerInfo
): LeagueVolunteer {
  if (routeName?.startsWith('new-volunteer')) {
    return getEmptyLeagueVolunteer(new LeagueVolunteerSeedInfo())
  }

  if (routeName?.startsWith('edit-volunteer')) {
    return {
      ...getEmptyLeagueVolunteer(new LeagueVolunteerSeedInfo()),
      ...leagueVolunteerInfoToLeagueVolunteer(currentVolunteer),
    }
  }

  if (routeName?.startsWith('copy-volunteer')) {
    return {
      ...getEmptyLeagueVolunteer(new LeagueVolunteerSeedInfo()),
      ...leagueVolunteerInfoToLeagueVolunteer(currentVolunteer),
      individualID: newPlayerID(),
    }
  }

  throw new VolunteerRouteRuntimeException('expected a valid routeName, got ' + routeName)
}

/**
 * Takes a URL our route and returns the category name part
 * @param routepath
 */
export function getRouteCategoryName(routepath: Route | string) {
  if (typeof routepath == 'string') {
    const matches = routepath.match(/volunteers\/(\S+)\/(\S+)/)
    if (matches?.length == 3) {
      return matches[2]
    }
    return ''
  }
  return routepath.params['type'] ?? ''
}

/**
 * Takes a URL our route and returns the category name part
 * @param routepath
 */
export function getLeagueType(routepath: Route | string) {
  if (typeof routepath == 'string') {
    const routeParts = routepath.split('/')
    if (routeParts?.length > 3) {
      return routeParts[2]
    }
    return ''
  }
  return ''
}
