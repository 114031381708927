
























import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'
import SearchModal from '@/elements/SearchModal.vue'

import {
  VolunteerSelectionGridInfo,
  volunteerSelectionHeaders,
} from '@/lib/components/CoachSelectionModal/VolunteerSelectionGridColumns'
import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'
import { RolesEnum, VolunteerIDType } from '@/lib/support/models/LeagueVolunteer/data'
import volunteersClient from '@/clients/volunteersClient'
import properCase from '@/filters/properCase'

function v2map(x: VolunteerSearchInfo, internalSelectedID: VolunteerIDType) {
  return {
    name: `${x.lastName}, ${x.firstName}`,
    gender: x.gender ?? '?',
    individualID: x.individualID,
    firstName: x.firstName ?? '',
    lastName: x.lastName ?? '',
    selected: internalSelectedID == x.individualID,
  }
}

export default defineComponent({
  name: 'VolunteerSelectionModal',
  components: {
    SearchModal,
  },
  filters: {
    properCase,
  },
  props: {
    league: { required: true, type: String, default: '' },
    roles: { type: Array as PropType<RolesEnum[]>, required: false, default: () => [0] },
    selectedID: { required: false, type: Number, default: -1 },
    searchTerm: { required: false, type: String, default: '' },
    selectedRecord: { required: false, type: Object as PropType<VolunteerSearchInfo | null>, default: null },
    title: { required: false, type: String, default: 'Coaches' },
    includePastLeagues: { required: false, type: Boolean, default: false },
    typeProgramID: { type: String, required: false, default: 'NONE' },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen } = useModalValue(props, ctx)
    const searchTimeout = ref<number | null>(null)
    const internalSelectedID = ref(-1)
    const searchData = ref<VolunteerSearchInfo[]>([])
    const search = ref('')
    const searchActive = ref(false) //not showing initially

    watch(
      () => props.selectedID,
      () => {
        updateSelected()
      },
      { immediate: true }
    )

    watch(
      () => props.searchTerm,
      () => {
        updateSelected()
      },
      { immediate: true }
    )

    function updateSelected() {
      search.value = props.searchTerm
      internalSelectedID.value = props.selectedID
    }

    function passiveSelect(p: VolunteerSearchInfo | null) {
      if (p?.individualID) {
        internalSelectedID.value = p.individualID
      } else {
        internalSelectedID.value = -1
      }
    }

    watch(
      () => isModalOpen.value,
      (b: boolean) => {
        if (!b) {
          close()
        }
      }
    )

    function close() {
      ctx.emit('close', true)
    }

    function searchSelected(p: VolunteerSearchInfo) {
      ctx.emit('select', p && searchData.value.find((x) => x.individualID == p.individualID))
    }

    const selectionData = computed((): VolunteerSelectionGridInfo[] => {
      let data = searchData.value.map((x) => v2map(x, internalSelectedID.value))
      if (
        props.selectedRecord &&
        props.selectedRecord.firstName && //make sure it's not an empty record
        data.findIndex((x) => x.individualID == props.selectedRecord?.individualID) < 0
      ) {
        data = [v2map(props.selectedRecord, internalSelectedID.value), ...data]
      }
      return data
    })

    /***
     * Callback on the SearchModal
     * @param s
     */
    function updateSearch(s: string) {
      //4656 - make them type at least 3 characters before searching
      if (s.trim().length > 2) {
        if (searchTimeout.value) {
          clearTimeout(searchTimeout.value)
        }

        searchTimeout.value = window.setTimeout(async () => {
          await doSearch(s)
        }, 1000)
      }
    }

    async function doSearch(s: string) {
      if (!s) {
        searchData.value = []
        return
      }

      if (!searchActive.value) {
        searchActive.value = true

        try {
          searchData.value = await volunteersClient.search({
            leagueID: props.league,
            first: s,
            last: s,
            email: '',
            phone: '',
            orSearch: true,
            role: props.roles.length == 1 && props.roles[0] == RolesEnum.COACH ? [RolesEnum.COACH] : [0], //if just coach then send coach else do what it has always done and return everyone
            includePastLeagues: props.includePastLeagues,
            coachTypeProgramID: props.typeProgramID,
          })
        } finally {
          searchActive.value = false
          searchTimeout.value = null
        }
      }
    }

    return {
      isModalOpen,
      volunteerSelectionHeaders,
      searchActive,
      selectionData,
      search,
      updateSearch,
      searchSelected,
      passiveSelect,
    }
  },
})
