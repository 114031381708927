































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import headers from './ext/transfer_gridcolumns'
import { VolunteerTransfer } from './model/VolunteerTransfer'
import Modal from '@/components/Modal.vue'
import DataTable from '@/elements/DataTable/DataTable.vue'
import SelectInput from '@/elements/SelectInput.vue'
import InputLabel from '@/elements/InputLabel.vue'
import TextInput from '@/elements/TextInput.vue'
import GradeRangeDropdowns from '@/elements/GradeRangeDropdowns.vue'
import Loading from '@/elements/Loading.vue'
import GenderSelect from '@/elements/GenderSelect.vue'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'

import store from '@/store'

export default defineComponent({
  name: 'VolunteerTransfer',
  components: {
    SelectInput,
    Modal,
    DataTable,
    GradeRangeDropdowns,
    GenderSelect,
    InputLabel,
    TextInput,
    Loading,
  },
  data() {
    return { headers }
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
    league: { type: String, required: false, default: '' },
  },
  setup(props, ctx) {
    const ids = ref<number[]>([])
    const transferAccount = ref('')
    const internalVisible = ref(false)
    const gender = ref('E')
    const name = ref('')
    const program = ref('BOTH')
    let localStatus = ref('')

    const transferStatus = computed(() => store.getters.volunteers.status)
    const volunteers = computed(() => cloneDeep(store.getters.volunteers.volunteers))
    const loading = computed(() => cloneDeep(store.getters.volunteers.loading))
    const loggedInAccount = computed(() => store.getters.authorization.firstAccountNumber)
    const transferAction = store.dispatch.volunteers.transferVolunteers
    const partners = computed(() => {
      const p = cloneDeep(store.getters.partners.partners)
      return p.filter((i) => i.accountNumber != loggedInAccount.value)
    })

    const programs = computed((): { label: string; id: string }[] => {
      const list = [...store.getters.leagueAbstraction.shortPrograms]
      list.push({ label: 'Both', id: 'BOTH' })
      return list
    })

    watch(
      () => props.visible,
      () => (internalVisible.value = props.visible),
      { immediate: true }
    )

    watch(
      () => partners.value,
      () => {
        if (transferAccount.value == '' && partners.value.length) {
          transferAccount.value = partners.value[0].accountNumber ?? ''
        }
      },
      { immediate: true }
    )

    watch(
      () => transferStatus.value,
      () => {
        if (transferStatus.value == 'Done' && localStatus.value != '') {
          setTimeout(() => (localStatus.value = ''), 2500)
        }
        localStatus.value = transferStatus.value
      }
    )
    const data = computed(() => {
      return volunteers.value
        .filter((x) => {
          const act = x.accountNumber == loggedInAccount.value
          const gd = gender.value == 'E' ? true : gender.value == x.gender
          const nm = stringContainsFilter(`${x.firstName}${x.lastName}`, name.value)
          let pg = false
          if (program.value == 'BOTH') {
            pg = true
          } else {
            const coachProgram = x.coachPreferences?.programs?.find((p) => p.typeProgramID == program.value)
            pg = coachProgram ? true : false
          }

          return act && gd && nm && pg
        })
        .map((x) => ({
          id: x.individualID,
          last: x.lastName,
          first: x.firstName,
          gender: x.gender,
        }))
    })
    function stringContainsFilter(playerVal: string, filter: string) {
      return filter != '' ? playerVal.toLowerCase().indexOf(filter.toLowerCase()) > -1 : true
    }

    function rowselected(a: DataTableSelection<VolunteerTransfer>, allSelected: VolunteerTransfer[]) {
      if (Array.isArray(allSelected) && allSelected.length) {
        ids.value = allSelected.map((x) => x.id)
      }
    }

    function clear() {
      program.value = 'BOTH'
      gender.value = 'E'
      name.value = ''
    }

    function close(b: boolean) {
      if (!b) {
        ctx.emit('close')
      }
    }
    function transfer() {
      transferAction({
        leagueID: props.league,
        ids: cloneDeep(ids.value),
        newAccount: transferAccount.value,
      })
    }

    return {
      loading,
      name,
      gender,
      program,
      programs,
      internalVisible,
      volunteers,
      close,
      data,
      partners,
      transferAccount,
      rowselected,
      localStatus,
      hasCheer: computed(() => store.getters.leagueAbstraction.hasCheer),
      clear,
      transfer,
    }
  },
})
