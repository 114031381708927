import { DecomposedArgs } from '@/views/Programs/Volunteers/ext/edittypes'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import { humanEmailToEmailID } from '@/lib/support/models/IndividualEmail/data'
import { WeedThePhoneList } from '@/lib/support/models/IndividualPhone/array_utils'
import { getFirstEmail } from '@/lib/support/models/IndividualEmail/email_list_operations'
import { stringToAddressTypeID } from '@/lib/support/models/IndividualAddress/data'
import { IndividualAddress } from '@/GeneratedTypes/IndividualAddress'

/**
 * Did the user fill out an address (e.g. did they type into street, city, etc
 * trying to avoid null-checks on fields that might be mandatory dropdowns
 *
 * @param a address to check
 * @return {boolean} has or not filled out
 */
function hasAddress(a: IndividualAddress) {
  if (a) {
    return !!(a.street1 || a.subdivision1 || a.subdivision2)
  }
  return false
}

/**
 * Builds a volunteer from field data, cleans up phones.
 * @param originalTemplate
 * @param args
 */
export function composeLeagueVolunteer(
  originalTemplate: LeagueVolunteer,
  args: DecomposedArgs
): LeagueVolunteer {
  return {
    ...args.volunteer,
    //oddly type size ID as null (which happens on import) can gum up save but not validate.
    typeSizeID: args.volunteer.typeSizeID ? args.volunteer.typeSizeID : '',
    coachPreferences: args.coachPreferences,
    individualID: originalTemplate.individualID,
    accountNumber: originalTemplate.accountNumber,
    leagueID: originalTemplate.leagueID,
    thirdPartyClientIDs: [],
    addresses: hasAddress(args.address)
      ? [
          {
            ...args.address,
            typeAddressID: args.address.typeAddressID
              ? args.address.typeAddressID
              : stringToAddressTypeID('home'),
            priority: 1,
          },
        ]
      : [],
    phoneNumbers: WeedThePhoneList(args.phones),
    roles: args.roles,
    emailAddresses: args.email
      ? [
          {
            ...getFirstEmail(originalTemplate),
            emailAddress: args.email,
            typeEmailID: humanEmailToEmailID('home'),
            priority: 1,
          },
        ]
      : [],
  }
}
