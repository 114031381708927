import { DataTableHeader } from 'vuetify/types'

export default [
  {
    value: 'selected',
    text: '',
  },
  {
    value: 'last',
    text: 'Last',
  },
  {
    value: 'first',
    text: 'First',
  },
  {
    value: 'gender',
    text: 'Gender',
  },
] as DataTableHeader[]
