import { IndividualPhoneInfo } from '@/GeneratedTypes/ListInfo/IndividualPhoneInfo'
import { PhoneTypeEnum } from '@/lib/support/models/IndividualPhone/data'

export function getEmptyIndividualPhoneInfo(): IndividualPhoneInfo {
  return {
    typePhoneID: PhoneTypeEnum.MOBILE,
    extension: '',
    priority: 1,
    phoneNumber: '',
    phoneID: 0,
    individualID: 0,
  }
}
