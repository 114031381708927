




























































































































































































































































import { Component, InjectReactive, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'

import { getterNames, namespace as volunteerStoreName } from '@/store/volunteers'
import { namespace as campStoreName, getterNames as campGetterNames } from '@/store/camps'
import { namespace as leagueStoreName } from '@/store/leagues'
import { leagueAbstractionNamespace as leagueAbstractionName } from '@/store/leagueAbstraction'
import { namespace } from 'vuex-class'
/**types */
import { League } from '@/GeneratedTypes/League'
import { Camp } from '@/GeneratedTypes/Camp'
/** external data */
import {
  coachHeaders,
  refHeaders,
  commissionerHeaders,
  othersHeaders,
  cheerDirectorHeaders,
} from './ext/headers'
/**components */
import FullBody from '@/components/FullBody.vue'
import SearchInput from '@/elements/SearchInput.vue'
import HorizontalTabs from '@/components/HorizontalNavTabs.vue'
import VolunteerTransfer from '@/views/Programs/Volunteers/VolunteerTransfer.vue'

import DataTable from '@/elements/DataTable/DataTable.vue'
import DeleteBtn from '@/elements/DataTable/vue/DeleteBtn.vue'
import EditBtn from '@/elements/DataTable/vue/EditBtn.vue'
import CheckboxCell from '@/elements/DataTable/vue/CheckboxCell.vue'
import InviteBtn from '@/views/Programs/Volunteers/ext/GridVue/InviteBtn.vue'
import InviteCell from '@/views/Programs/Volunteers/ext/GridVue/InviteCell.vue'

/** local components */
import TeamInfo from '@/components/TeamInfo.vue'
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue'
import { intersection, cloneDeep, uniq } from 'lodash'
import {
  getEmptyLeagueListItem,
  LeagueInfoMoreThanOneProgram,
  LeagueListItemSeed,
} from '@/lib/support/models/LeagueListItem/data'

import VolunteerSelectionModal from '@/components/VolunteerSelectionModal.vue'
import VolunteerEmailPhoneSearch from '@/components/VolunteerEmailPhoneSearch.vue'
import VolunteerRoleAllowOnline from '@/components/VolunteerRoleAllowOnline.vue'
import { RolesEnum, VolunteerID, VolunteerIDType } from '@/lib/support/models/LeagueVolunteer/data'
import { LeagueVolunteerInfo } from '@/GeneratedTypes/ListInfo/LeagueVolunteerInfo'
import { getRouteCategoryName, getLeagueType } from '@/views/Programs/Volunteers/ext/routeutils'
import teamsClient from '@/clients/teamsClient'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { LeagueListItem } from '@/models/Program/LeagueListItem'
import { LeagueProgams2LeagueListItem } from '@/lib/support/models/LeagueListItem/LeaguePrograms2LeagueListItem'
import TypeProgramTab from '@/components/TypeProgramTab.vue'
import {
  CategoryNameToCategory,
  categoryToRoles,
  CategoryWithProgram,
  VolunteerSectionTypes,
  categoryToTitle,
} from '@/views/Programs/Volunteers/ext/roleutils'
import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import { InvitationStatusEnum, VolunteerListInfo } from '@/views/Programs/Volunteers/ext/gridcolumntypes'
import InvitationResendModal, {
  ResendInvitePayload,
  ResendInvitePayloadMedium,
} from '@/views/Programs/Volunteers/vues/InvitationResendModal.vue'
import InvitationSendModal, {
  SendInvitePayload,
  SendInvitePayloadMedium,
} from '@/views/Programs/Volunteers/vues/InvitationSendModal.vue'

import { InviteContact } from '@/models/VolunteerManagement/Invitations/InviteContact'

import { getFirstEmailInfo } from '@/lib/support/models/ListInfo/IndividualEmailInfo/emailinfo_list_operations'
import { getFirstPhoneInfo } from '@/lib/support/models/ListInfo/IndividualPhoneInfo/phoneinfo_list_operations'
import dayjs from 'dayjs'
import Alert, { AlertTypeEnum } from '@/components/Alert.vue'

import Loading from '@/elements/Loading.vue'
import { roleById } from '@/lib/support/models/UpwardTypes/TypeLeagueVolunteerRole/data'
import jwtService from '@/services/jwtService'
import credentialsService from '@/services/credentialsService'
import VolunteerImport from '@/views/Programs/Volunteers/vues/VolunteerImport.vue'

const volunteers = namespace(volunteerStoreName)
const league = namespace(leagueStoreName)
const camp = namespace(campStoreName)
const leagueAbstraction = namespace(leagueAbstractionName)
import store from '@/store'
import { useFeatureFlags } from '@/services/useFeatureFlags'
import properCase from '@/filters/properCase'
import { formatPhoneNumber } from '@/services/phoneNumberService'
import { DataTableSelection, DataTablePagination } from '@/models/DataTable/DataTableSelection'
import volunteersClient from '@/clients/volunteersClient'

interface ControlToggles {
  hasImport: boolean
  hasBatchInvite: boolean
  hasNewButtons: boolean
  hasSearch: boolean
  hasTeamInfo: boolean
  hasApproveAll: boolean
  hasTransfer: boolean
  /** role id to use in approve all **/
  approvalRole: RolesEnum | false
  hasTypeProgramTab: boolean
  hasAllowRoleOnline: boolean
}

const noControlsOn: ControlToggles = {
  hasImport: false,
  hasBatchInvite: false,
  hasNewButtons: false,
  hasSearch: false,
  hasTeamInfo: false,
  hasApproveAll: false,
  approvalRole: false,
  hasTransfer: false,
  hasTypeProgramTab: false,
  hasAllowRoleOnline: false,
}

const mostControlsOff: ControlToggles = {
  ...noControlsOn,
  hasNewButtons: true,
  hasSearch: true,
}

const tabToInterfaceMap: Record<VolunteerSectionTypes, ControlToggles> = {
  [VolunteerSectionTypes.ALL]: { ...noControlsOn, hasSearch: true },
  [VolunteerSectionTypes.REFEREES]: {
    ...mostControlsOff,
    hasSearch: true,
    hasBatchInvite: true,
    hasApproveAll: true,
    approvalRole: RolesEnum.REFEREE,
    hasAllowRoleOnline: true,
  },
  [VolunteerSectionTypes.COMMISSIONERS]: { ...mostControlsOff, hasBatchInvite: true },
  [VolunteerSectionTypes.OTHER]: mostControlsOff,
  [VolunteerSectionTypes.COACHES]: {
    hasBatchInvite: true,
    hasImport: true,
    hasNewButtons: true,
    hasSearch: true,
    hasTeamInfo: true,
    hasApproveAll: true,
    approvalRole: RolesEnum.COACH,
    hasTransfer: true,
    hasTypeProgramTab: true,
    hasAllowRoleOnline: true,
  },
  [VolunteerSectionTypes.CHEERDIRECTOR]: {
    hasBatchInvite: true,
    hasImport: false,
    hasNewButtons: true,
    hasSearch: true,
    hasTeamInfo: true,
    hasApproveAll: true,
    approvalRole: RolesEnum.COACH,
    hasTransfer: false,
    hasTypeProgramTab: false,
    hasAllowRoleOnline: false,
  },
}

const ALL_PROGRAMS = ''

@Component({
  components: {
    Loading,
    VolunteerImport,
    InvitationResendModal,
    InvitationSendModal,
    ConfirmationModal,
    FullBody,
    TypeProgramTab,
    ConfirmDeleteModal,
    HorizontalTabs,
    SearchInput,
    TeamInfo,
    Alert,
    VolunteerSelectionModal,
    VolunteerTransfer,
    VolunteerEmailPhoneSearch,
    VolunteerRoleAllowOnline,
    DataTable,
    EditBtn,
    DeleteBtn,
    CheckboxCell,
    InviteBtn,
    InviteCell,
  },
  methods: {
    LeagueInfoMoreThanOneProgram,
  },
  setup() {
    return { ...useFeatureFlags() }
  },
  filters: {
    properCase,
  },
})
export default class VolunteerView extends Vue {
  /** index into this.currentItem.programs[] variables  */

  searchTerm = ''
  showDelete = false
  @volunteers.Getter('loading') loading!: boolean
  @volunteers.Getter('currentProgram') currentStoreProgram!: string
  @leagueAbstraction.Getter('isInPartnership') isInPartnership!: boolean
  @volunteers.Getter('pagination') pagination!: DataTablePagination

  /** getters */
  @league.Getter(getterNames.currentItem) league!: League
  @volunteers.Getter(getterNames.volunteers) volunteers!: LeagueVolunteerInfo[]
  @volunteers.Getter(getterNames.currentItem) currentVolunteer!: LeagueVolunteerInfo
  @camp.Getter(campGetterNames.currentItem) camp!: Camp

  /** actions */
  setAllApproved = store.dispatch.volunteers.setAllApproved
  setAllInvited = store.dispatch.volunteers.setAllInvited
  setAllInvitedViaSms = store.dispatch.volunteers.setAllInvitedViaSms
  removeVolunteer = store.dispatch.volunteers.removeVolunteer
  loadLeanVolunteersByProgramRole = store.dispatch.volunteers.loadLeanVolunteersByProgramRole
  setCurrentVolunteerByID = store.dispatch.volunteers.setCurrentVolunteerByID
  setCurrentStoreProgram = store.commit.volunteers.setCurrentProgram
  clearCurrentVolunteer = store.commit.volunteers.clearCurrent
  clearVolunteerUnderEdit = store.commit.volunteers.clearVolunteerUnderEdit
  setPagination = store.commit.volunteers.setPagination

  private emailPhoneVolunteerSearchActive = false

  private readonly alertType = AlertTypeEnum.NOTICE
  private leagueid = '' //aught to be set by the router.

  @InjectReactive()
  private isHistoricalProgram!: boolean

  private get volunteerSelectionModalTitle() {
    return properCase(categoryToTitle(this.category))
  }

  get routeType(): string {
    return getLeagueType(this.$route.fullPath)
  }

  get currentItem() {
    if (this.routeType === 'league') return this.league
    if (this.routeType === 'camp') return this.camp
  }

  private get showInvitationStatus() {
    return this.category !== VolunteerSectionTypes.OTHER
  }

  get headers() {
    switch (this.volunteerType) {
      case 'coaches':
        return coachHeaders
      case 'referees':
        return refHeaders
      case 'commissioners':
        return commissionerHeaders
      case 'cheerdirector':
        return cheerDirectorHeaders
      case 'others':
        return othersHeaders
      default:
        throw new Error('Cannot select DataTable headers')
    }
  }

  get volunteerType() {
    const routePart = this.$route.params.type
    const idx = routePart.indexOf('-') // cheer is sometimes appended to volunteer type; delimited by '-'
    const volunteerType = idx == -1 ? routePart : routePart.substring(0, idx)
    return volunteerType ? volunteerType : 'coaches'
  }

  private allApproved = true
  /**
   * Approve All clicked on grid header
   */
  approveClick() {
    if (this.approveRoleID !== false) {
      this.setAllApproved({
        ids: this.visibleVolunteers.map((x) => x.individualID) || [],
        leagueID: this.leagueid || '',
        role: this.approveRoleID,
        approved: !this.isEveryoneApproved,
      })
      this.allApproved = !this.allApproved
    }
  }
  get isEveryoneApproved() {
    return !this.visibleVolunteers.some((x) => !x.approved)
  }

  private getFirstEmailInfo = getFirstEmailInfo
  private getFirstPhoneInfo = getFirstPhoneInfo

  private get approveRoleID() {
    return this.interfaceToggles.approvalRole // may change to a referee
  }

  async refreshVolunteers(shouldForceRefresh: boolean) {
    await this.loadLeanVolunteersByProgramRole({
      leagueId: this.leagueid,
      shouldForce: shouldForceRefresh,
      typeProgramId: this.currentProgram === ALL_PROGRAMS ? 'NONE' : this.currentProgram,
      roleIDs: categoryToRoles(this.category),
    })
  }

  isImportVisible = false
  importClicked() {
    this.isImportVisible = true
  }

  async closeImport() {
    this.isImportVisible = false
    await this.refreshVolunteers(true)
  }

  async transferClosed() {
    this.transferActive = false
    await this.navigateBackToList()
  }

  async searchClosed() {
    this.existingVolunteerSearchActive = false
    this.emailPhoneVolunteerSearchActive = false
    await this.navigateBackToList()
  }

  async navigateBackToList() {
    let type = ''
    if (this.$route.params.type) {
      type = this.$route.params.type.replaceAll('-', '')
    }
    await this.$router.push({
      name: `list-volunteer-${this.routeType}`,
      params: { type: type, ...this.$route.params },
    })
  }

  onPagination(data: DataTablePagination) {
    this.setPagination({ pagination: data })
  }

  /***
   * Approve clicked on the row
   */
  individualClick(d: VolunteerListInfo) {
    if (this.approveRoleID !== false) {
      this.setAllApproved({
        ids: [d.individualID],
        approved: !d.approved,
        leagueID: this.leagueid || '',
        role: this.approveRoleID,
      })
    }
  }

  get tabs(): LeagueListItem {
    if (this.currentItem && this.currentItem.programs) {
      return LeagueProgams2LeagueListItem(this.currentItem.programs)
    }
    return getEmptyLeagueListItem(new LeagueListItemSeed())
  }

  get hasOneRole() {
    //The API supports searches for one role at time.
    //hasOneRole is used to limit the email and phone search
    //to Referees and Coaches because they have only one role.
    //Commissioners and Other Volunteers will come later
    return this.rolesByProgram.length == 1
  }

  /***
   * Returns the structure to define the tabs based on loaded league
   */
  /** index into this.currentItem.programs[] variables  */

  get currentProgram(): string {
    if (!LeagueInfoMoreThanOneProgram(this.tabs)) {
      const progs = this.league.programs
      const program = progs && progs.length ? progs[0].typeProgramID : ''
      this.setCurrentStoreProgram({ program })
      return ALL_PROGRAMS
    }
    return this.currentStoreProgram
  }
  set currentProgram(program: string) {
    if (this.$route.params['type'] != CategoryWithProgram(this.category, program)) {
      this.$router.push({
        name: `list-volunteer-${this.routeType}`,
        params: { ...this.$route.params, type: CategoryWithProgram(this.category, program) },
      })
    }
    this.setCurrentStoreProgram({ program })
  }

  clearTypeProgram() {
    if (this.category != 'coaches') {
      this.currentProgram = ''
    }
  }

  get combinedCategoryAndProgram() {
    return CategoryWithProgram(this.category, this.currentProgram)
  }

  private get interfaceToggles(): ControlToggles {
    return tabToInterfaceMap[this.category] ?? noControlsOn
  }

  private transferActive = false
  transfer() {
    this.transferActive = true
  }

  private existingVolunteerSearchActive = false
  private category: VolunteerSectionTypes = VolunteerSectionTypes.ALL
  /***
   * Route changing logic, looking at handling delete, and index, Existing mostly,
   * for delete we need a volunteer id.
   */
  @Watch('$route', { immediate: true, deep: true })
  async routeChange(to: Route) {
    this.category = CategoryNameToCategory(getRouteCategoryName(to))
    this.leagueid = to?.params.id
    try {
      if (to?.params.id) {
        this.refreshVolunteers(true)
      }
      if (to?.params?.vid) {
        await this.setCurrentVolunteerByID({ id: parseInt(to.params.vid) })
      }
    } catch (e) {
      await this.$router.push('../' + this.category)
      throw new Error(e.message)
    }

    if (to.name == `delete-volunteer-${this.routeType}` && to.params?.vid) {
      this.deleteVolunteer()
      return
    }
    if (to.name?.startsWith('transfer-volunteer')) {
      this.transfer()
      return
    }
    if (to.name?.startsWith(`search-volunteer`)) {
      this.emailPhoneParticipantSearch()
      return
    }
    if (to.name == `former-volunteer-${this.routeType}`) {
      this.existingVolunteerSearchActive = true
      return
    }
    //else...
    this.existingVolunteerSearchActive = false
  }
  /**
   * Gives an array of roles based on the category selected
   */
  get rolesByProgram() {
    return categoryToRoles(this.category)
  }

  async volunteerFound(vs: VolunteerSearchInfo) {
    try {
      await this.setCurrentVolunteerByID({ id: vs.individualID })
      await this.$router.push({
        name: `edit-volunteer-${this.routeType}`,
        params: { vid: vs.individualID.toString(), ...this.$route.params },
      })
    } catch (error) {
      // not an existing volunteer
      await this.$router.push({
        name: `copy-volunteer-${this.routeType}`,
        params: { vid: vs.individualID.toString(), ...this.$route.params },
      })
    }
  }
  /**
   * Invite icon is clicked.
   */
  async invite(l: VolunteerListInfo) {
    await this.setCurrentVolunteerByID({ id: l.individualID }) //this will retrieve full volunteer info
    this.pendingInviteVolunteer = { ...this.listToListInfo(this.currentVolunteer) }

    if (l.invitationStatus == InvitationStatusEnum.EMPTY) {
      this.showInviteConfirm = true
    } else {
      this.showResendInviteConfirm = true
    }
  }

  private showEmailInviteSent = false
  private showSmsInviteSent = false
  private smsInviteSentNumber = ''
  private showInviteStatusError = false

  async onResendInviteConfirm(payload: ResendInvitePayload) {
    if (!this.pendingInviteVolunteer) {
      return
    }

    if (payload.medium === ResendInvitePayloadMedium.EMAIL) {
      await this.setAllInvited({
        ids: [this.vol2contact(this.pendingInviteVolunteer)],
        leagueID: this.leagueid || '',
      })
      this.showEmailInviteSent = true

      return
    }

    if (payload.medium === ResendInvitePayloadMedium.SMS) {
      const inviteContact = this.vol2contact(this.pendingInviteVolunteer)

      if (payload.phoneNumber) {
        // Allow the user to override the phone number using the control within the modal
        inviteContact.phone = payload.phoneNumber
        this.smsInviteSentNumber = formatPhoneNumber(payload.phoneNumber)
      } else {
        this.smsInviteSentNumber = formatPhoneNumber(inviteContact.phone)
      }

      await this.setAllInvitedViaSms({
        ids: [inviteContact],
        leagueID: this.leagueid || '',
      })
      this.showSmsInviteSent = true

      return
    }
  }

  public onShowInviteStatusErrorClick() {
    if (this.showInviteStatusError) {
      this.showInviteStatusError = false
    } else {
      this.showInviteStatusError = true
    }
  }

  /**
   * On send invite there is a confirmation.
   * we'll call this on confirm if the modal is confirmed,
   * invite will call this function directly if confirm is not
   * required.
   */
  async onInviteConfirm(payload: SendInvitePayload) {
    if (!this.pendingInviteVolunteer) {
      return
    }

    if (payload.medium === SendInvitePayloadMedium.EMAIL) {
      await this.setAllInvited({
        ids: [this.vol2contact(this.pendingInviteVolunteer)],
        leagueID: this.leagueid || '',
      })
      this.showEmailInviteSent = true

      return
    }

    if (payload.medium === SendInvitePayloadMedium.SMS) {
      const inviteContact = this.vol2contact(this.pendingInviteVolunteer)

      if (payload.phoneNumber) {
        // Allow the user to override the phone number using the control within the modal
        inviteContact.phone = payload.phoneNumber
        this.smsInviteSentNumber = formatPhoneNumber(payload.phoneNumber)
      } else {
        this.smsInviteSentNumber = formatPhoneNumber(inviteContact.phone)
      }

      await this.setAllInvitedViaSms({
        ids: [inviteContact],
        leagueID: this.leagueid || '',
      })
      this.showSmsInviteSent = true

      return
    }
  }

  /**
   * Converts a VLI to IC  to send an invite.
   */
  vol2contact(i: VolunteerListInfo): InviteContact {
    return {
      id: i.individualID,
      accountNumber: i.accountNumber ?? '',
      name: `${i.firstName} ${i.lastName}`,
      email: getFirstEmailInfo(i).emailAddress || '',
      phone: '',
      roles: (i.roles || []).map((x) => x.roleID),
    }
  }

  showInviteConfirm = false
  showResendInviteConfirm = false
  pendingInviteVolunteer: VolunteerListInfo | null = null
  showConfirmBatchInvite = false
  showBatchInviteSent = false
  batchInviteLastSentList: VolunteerListInfo[] = []
  /**
   * Batch invite was clicked. */
  batchInvite() {
    this.showConfirmBatchInvite = true
  }
  /***
   * Identifies folks that need to get a batch invite
   */
  async onBatchConfirm(actionConfirmed: boolean) {
    this.showConfirmBatchInvite = false
    if (!actionConfirmed) {
      return
    }
    this.showBatchInviteSent = true
    let batchInvitableList = await Promise.all(
      this.visibleVolunteers
        .filter((x) => x.approved && !x.inviteExpireDate && x.inviteAllowed)
        .map(async (x) =>
          this.listToListInfo(
            (await volunteersClient.getFullVolunteerInfo(this.leagueid, x.individualID)) ?? x
          )
        )
    )
    batchInvitableList = batchInvitableList.filter((x) => (x.emailAddresses?.length ?? 0) > 0)
    this.batchInviteLastSentList = cloneDeep(uniq(batchInvitableList))
    actionConfirmed &&
      (await this.setAllInvited({
        ids: batchInvitableList.map((x) => this.vol2contact(x)),
        leagueID: this.leagueid || '',
      }))
  }

  get batchInviteButtonDisable() {
    return (
      this.visibleVolunteers.filter((x) => x.approved && !x.inviteExpireDate && x.inviteAllowed).length == 0
    )
  }

  get account() {
    return credentialsService.getPrimaryAccountFromCredentials(jwtService.getCredentialsFromStoredAuthToken())
  }

  /***
   * Visible volunteers are influenced by the current tab
   */
  get visibleVolunteers(): VolunteerListInfo[] {
    return this.volunteers
      .filter(
        (x) =>
          x.firstName &&
          x.lastName &&
          `${x.firstName} ${x.lastName!}`.toLocaleLowerCase().search(this.searchTerm.toLocaleLowerCase()) !=
            -1
      )
      .sort((a, b) => (`${a.lastName}, ${a.firstName}` > `${b.lastName}, ${b.firstName}` ? 1 : -1))
      .map(this.listToListInfo)
  }

  invitationStatus(v: LeagueVolunteerInfo) {
    //invalid email addresses, should show an invalid status to user

    if (v.inviteAcceptDate) {
      return InvitationStatusEnum.ACCEPTED
    }

    if (!v.inviteExpireDate) {
      return InvitationStatusEnum.EMPTY
    }

    if (dayjs(v.inviteExpireDate).diff(dayjs()) < 0) {
      return InvitationStatusEnum.EXPIRED
    }
    return InvitationStatusEnum.SENT
  }

  listToListInfo(v: LeagueVolunteerInfo): VolunteerListInfo {
    return {
      ...v,
      approved: v.roles?.find((x) => x.roleID == this.approveRoleID)?.approved ?? true,
      invitationStatus: this.invitationStatus(v),
      prettyName: `${v.lastName}, ${v.firstName}`,
      roles_text:
        v.roles?.map((x) => roleById(x.roleID, this.currentItem?.roles ?? [])?.description)?.join(',') ?? '',
    }
  }

  private currentRoleInfo() {
    return roleById(Number(this.approveRoleID.valueOf()), this.currentItem?.roles ?? [])
  }

  private currentRoleAllowsOPR() {
    const roleInfo = this.currentRoleInfo()
    if (roleInfo) {
      return roleInfo.allowInOPR
    } else return false
  }

  private currentRoleAllowsOVR() {
    const roleInfo = this.currentRoleInfo()
    if (roleInfo) {
      return roleInfo.allowInOVR
    } else return false
  }

  private programHasOPR() {
    return this.currentItem?.isOPRLeague
  }

  deleteVolunteer() {
    this.showDelete = true
  }

  async editVolunteer(individualId: number) {
    await this.$router.push(
      `/programs/${this.routeType}/${this.leagueid}/volunteers/edit/${this.volunteerType}/${individualId}`
    )
  }

  /***
   * User confirmed a delete
   */
  deletingId = 0
  async confirmDeleteVolunteer() {
    this.deletingId = this.currentVolunteer.individualID
    // this store will call into the client and then process the last-role logic
    // specifically we will remove all roles from the volunteer and then call delete on the volunteer
    // if all roles have been removed.
    try {
      await this.removeVolunteer({
        upwardLeagueID: this.leagueid || '',
        id: this.currentVolunteer.individualID,
        roles: intersection(
          this.rolesByProgram,
          this.currentVolunteer.roles?.map((x) => x.roleID)
        ),
      })
    } finally {
      this.deletingId = 0
    }
  }
  cancelDeleteVolunteer() {
    this.showDelete = false
  }
  private teamInfo: DivisionTeamInfo[] = []
  async setLocalCurrentVolunteerID(id: VolunteerIDType) {
    await this.setCurrentVolunteerByID({ id })
    this.teamInfo = await teamsClient.retrieveTeamInfo({
      leagueID: this.leagueid ?? '',
      individualId: id,
    })
  }

  rowSelected(x: DataTableSelection<LeagueVolunteerInfo>) {
    const id = VolunteerID(x.item)
    if (id) {
      this.setLocalCurrentVolunteerID(id)
    }
  }
  async rowDoubleClicked(x: DataTableSelection<LeagueVolunteerInfo>) {
    await this.$router.push({
      name: `edit-volunteer-${this.routeType}`,
      params: { vid: VolunteerID(x.item).toString(), ...this.$router.currentRoute.params },
    })
  }

  mount() {
    this.setAllApproved = store.dispatch.volunteers.setAllApproved
    this.setAllInvited = store.dispatch.volunteers.setAllInvited
    this.removeVolunteer = store.dispatch.volunteers.removeVolunteer
    this.loadLeanVolunteersByProgramRole = store.dispatch.volunteers.loadLeanVolunteersByProgramRole
    this.setCurrentVolunteerByID = store.dispatch.volunteers.setCurrentVolunteerByID
    this.setCurrentStoreProgram = store.commit.volunteers.setCurrentProgram
    this.clearCurrentVolunteer = store.commit.volunteers.clearCurrent
    this.clearVolunteerUnderEdit = store.commit.volunteers.clearVolunteerUnderEdit
  }

  destroyed() {
    this.clearCurrentVolunteer()
    this.clearVolunteerUnderEdit()
  }

  emailPhoneParticipantSearch() {
    this.emailPhoneVolunteerSearchActive = true
  }

  get addButtonEnabled(): boolean {
    if (this.category !== VolunteerSectionTypes.CHEERDIRECTOR) {
      return true
    }
    return this.visibleVolunteers.length === 0
  }
}
