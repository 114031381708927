




































































import { defineComponent, PropType, ref, watch, computed } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import { FileToSet } from '@/views/Programs/Participants/vues/import/xls_import'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { League } from '@/GeneratedTypes/League'
import { Camp } from '@/GeneratedTypes/Camp'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import { ValidationError } from 'yup'
import ImportErrorsTable, { ImportErrorsType } from '@/views/Programs/Participants/vues/ImportErrorsTable.vue'
import ProgressIndicator from '@/views/Programs/Participants/vues/ProgressIndicator.vue'
import { cloneDeep } from 'lodash'
import { LeagueVolunteer } from '@/GeneratedTypes/LeagueVolunteer'
import volunteersClient from '@/clients/volunteersClient'
import { VolunteerPartsReturn, useVolunteerImport } from '@/composables/VolunteerImport'
import { composeLeagueVolunteer } from '@/views/Programs/Volunteers/ext/composeutils'
import {
  getEmptyLeagueProgram,
  ProgramHasGradeAndGender,
  leagueProductImportMapping,
  getProducts,
} from '@/lib/support/models/LeagueProgram/data'
import { LeagueVolunteerRoleUDFDefinition } from '@/GeneratedTypes/LeagueVolunteerRoleUDFDefinition'
import { VolunteerRoleUDFValue } from '@/GeneratedTypes/VolunteerRoleUDFValue'
import { getEmptyCoachProgram } from '@/lib/support/models/CoachProgram/data'
import { DuplicateOptions, duplicateOptionList } from '@/views/Programs/Participants/ext/import'
import { LeagueProgram } from '@/GeneratedTypes/LeagueProgram'
import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import { CoachAssistantLink, empty as emptyCoachAssistantLink } from '@/GeneratedTypes/CoachAssistantLink'

export default defineComponent({
  name: 'VolunteerImport',

  components: { ProgressIndicator, ImportErrorsTable, CheckboxInput, Modal, RadioGroupInput },
  props: {
    visible: { type: Boolean, required: true, default: false },
    league: { type: Object as PropType<League | Camp>, required: true, default: () => getEmptyLeague() },
  },
  setup(props, ctx) {
    const duplicateChoices = ref(duplicateOptionList)
    const duplicateChoice = ref(duplicateOptionList[0].value)
    const internalVisible = ref(false)
    const step = ref(1)
    const errors = ref<string[]>([])
    const import_errors = ref<ImportErrorsType[]>([])
    const shouldShowErrors = ref(true)
    const imported = ref(0)
    const total = ref(0)
    const text = ref('')
    const saved = ref(0)

    watch(
      () => props.visible,
      () => (internalVisible.value = props.visible),
      { immediate: true }
    )

    watch(
      () => internalVisible.value,
      () => {
        //reset the step to first if the window is closed.
        if (internalVisible.value) {
          step.value = 1
          imported.value = 0
          total.value = 0
          text.value = ''
          saved.value = 0
          duplicateChoice.value = duplicateOptionList[0].value
          import_errors.value = []
        }
      },
      { immediate: true }
    )

    function close(b: boolean) {
      if (!b) {
        //closed
        step.value = 3

        ctx.emit('close')
      }
    }

    function showErrors() {
      shouldShowErrors.value = !shouldShowErrors.value
    }

    const validPracticeDays = computed(() => {
      if ('practiceNights' in props.league) {
        //for leagues
        return (props.league.practiceNights ?? []).map((p) => p.typeDayID)
      }
      // for camps
      return []
    })

    /**
     * Load a player from the DB
     * @param duplicate
     * @param volunteer
     */
    async function getVolunteerFromList(
      duplicate: number,
      volunteer: VolunteerPartsReturn,
      line: number
    ): Promise<LeagueVolunteer | null> {
      let mergeVolunteer: LeagueVolunteer | null = null

      const foundVol = await volunteersClient.search({
        leagueID: props.league?.upwardLeagueID ?? '',
        role: [RolesEnum.COACH],
        orSearch: false,
        first: volunteer.volunteer.firstName,
        last: volunteer.volunteer.lastName,
        includePastLeagues: false,
      })

      const existingID = foundVol.length ? foundVol[0].individualID : null

      if (existingID && duplicate == DuplicateOptions.SKIP) {
        import_errors.value.push({
          line: line,
          error: `Duplicate: skipping ${volunteer.volunteer.firstName} ${volunteer.volunteer.lastName}`,
        })
        return null
      }

      if (existingID && duplicate == DuplicateOptions.UPDATE) {
        mergeVolunteer = await volunteersClient.retrieveVolunteer(
          props.league?.upwardLeagueID ?? '',
          existingID
        )
        return cloneDeep(mergeVolunteer)
      }

      //new volunteer
      mergeVolunteer = await volunteersClient.retrieveTemplateWithRole(
        props.league?.upwardLeagueID ?? '',
        getVolunteerProgram(volunteer.isSport).typeProgramID,
        RolesEnum.COACH
      )
      return cloneDeep(mergeVolunteer)
    }

    function getVolunteerProgram(isSport: boolean): LeagueProgram {
      if (!props.league?.programs?.length) return getEmptyLeagueProgram()
      if (isSport) {
        return props.league.programs[0]
      } else {
        return (
          props.league.programs.find((x) => x.typeProgramID.match(/.*CHEER.*/)) ?? getEmptyLeagueProgram()
        )
      }
    }

    async function uploadFiles(ev: InputEvent) {
      const t = ev.target as HTMLInputElement
      /**
       * get the mapping that connects the spreadsheet column name and the product IDs
       */
      const mappings = await leagueProductImportMapping(props.league.upwardLeagueID ?? '')
      const volImport = useVolunteerImport()
      await volImport.buildSchema(mappings)

      if (t?.files?.length) {
        try {
          step.value = 2
          const sheet = await FileToSet(t.files[0])

          if (!sheet || sheet?.length <= 0) {
            text.value = 'Uploaded file not recognized'
          } else {
            try {
              await volImport.validateVolunteer(sheet[0], true)
            } catch (e) {
              text.value = 'First row has import errors, check the file'
              if (e.errors?.length) {
                e.errors.map((x: string) => import_errors.value.push({ line: 0, error: x }))
                step.value = 3
              }
            }
          }

          const createCoachLink = (v: VolunteerPartsReturn): CoachAssistantLink | null => {
            const first = v.coachLinkFirstName
            const last = v.coachLinkLastName
            const link = first && last ? emptyCoachAssistantLink() : null
            if (link) {
              link.pendingFirstName = first
              link.pendingLastName = last
            }
            return link
          }

          let line = 0
          total.value = sheet.length
          /**
           * Step is being used for state 1=file selection; 2=importing; 3=cancelled/done state
           */
          for (let i = 0; i < sheet.length && step.value <= 2; i++) {
            let r = sheet[i]
            try {
              line++
              imported.value = line
              const p = await volImport.validateVolunteer(r)
              text.value = `Line ${line + 1}}`
              if (p) {
                text.value = `Line ${line + 1} ${p.FirstName} ${p.LastName}`
                const parts = volImport.VolunteerXLToVolunteerParts(
                  p,
                  mappings.columnNames,
                  validPracticeDays.value
                )

                const mergeVolunteer = await getVolunteerFromList(duplicateChoice.value, parts, line)

                if (mergeVolunteer) {
                  try {
                    const volunteerProgram = getVolunteerProgram(parts.isSport)
                    let udfs: LeagueVolunteerRoleUDFDefinition[] = []
                    if (props.league.roles?.length) {
                      //assume sports program.
                      const volunteerRole = props.league.roles.find((x) => x.id == 3)
                      if (volunteerRole) {
                        udfs = volunteerRole.udfDefinitions ?? []
                      }
                    }

                    let newudfs: VolunteerRoleUDFValue[] = []
                    if (parts.roles.length) {
                      //the first and only program is the sports program so UDF1...3 are sports
                      newudfs = udfs.map(
                        (x) =>
                          ({
                            udfValue: parts.roles[0].udFs?.length
                              ? parts.roles[0].udFs.find((u) => u.udfid == x.sortOrder)?.udfValue ?? ''
                              : '',
                            udfid: x.udfid,
                          } as VolunteerRoleUDFValue)
                      )
                    }
                    const lv = composeLeagueVolunteer(mergeVolunteer, {
                      volunteer: parts.volunteer,
                      roles: [{ ...parts.roles[0], udFs: newudfs.filter((x) => x.udfValue) }],

                      phones: parts.volunteer.phoneNumbers ?? [],

                      email: parts.email,
                      address: parts.address,
                      coachPreferences: {
                        ...mergeVolunteer.coachPreferences,
                        programs: [
                          {
                            ...getEmptyCoachProgram(),
                            gendersAndGrades: parts.grades.filter((x) =>
                              ProgramHasGradeAndGender(volunteerProgram, x.genderToCoach, x.typeGradeID)
                            ),
                            products: getProducts(mergeVolunteer, volunteerProgram),
                            typeProgramID: volunteerProgram.typeProgramID,
                            assistantCoachLink: createCoachLink(parts),
                          },
                        ],
                        practiceNights: parts.practiceNights,
                      },
                    })

                    if (!lv.coachPreferences || !lv.coachPreferences.programs) {
                      throw Error('Missing coach preferences')
                    }
                    const programs = lv.coachPreferences.programs
                    if (programs && programs.length) {
                      programs.forEach((prog) => {
                        if (!prog || !prog.products?.length) return
                        prog.products.forEach((p) => {
                          const map = mappings.leagueProductMappings.find((m) => {
                            return m.gender == lv.gender && m.productID == p.productID
                          })
                          if (map) {
                            const xlProduct = parts.products.find((part) => {
                              return part.columnName == map.importColumnName
                            })
                            if (xlProduct) {
                              p.typeSizeID = xlProduct.value
                            }
                          }
                        })
                      })
                    }
                    await volunteersClient.save(props.league?.upwardLeagueID ?? '', lv, false, true)
                    saved.value++
                  } catch (e) {
                    if (e.errorObject?.errors?.length) {
                      e.errorObject.errors.forEach((x: { propertyName: string; message: string }) => {
                        import_errors.value.push({ line, error: `Server: ${x.propertyName} ${x.message}` })
                      })
                    }
                    if (e.errorObject?.message) {
                      import_errors.value.push({ line, error: e.errorObject.message })
                    } else {
                      import_errors.value.push({ line, error: e.getMessage() })
                    }
                  }
                }
              }
            } catch (e) {
              //likely validation error
              if (e instanceof ValidationError || e.errors) {
                import_errors.value.push({ line, error: e.message })
              }
            }
          }

          step.value = 2
        } catch (e) {
          step.value = 2
          errors.value = e.getMessage()
        } finally {
          step.value = 3
          text.value = 'Done.'
          ctx.emit('imported')
        }
      }
    }
    return {
      internalVisible,
      close,
      step,
      total,
      uploadFiles,
      import_errors,
      errors,
      saved,
      imported,
      showErrors,
      text,
      shouldShowErrors,
      duplicateChoices,
      duplicateChoice,
    }
  },
})
