




import { defineComponent, PropType, computed } from '@vue/composition-api'
import { InvitationStatusEnum, VolunteerListInfo } from '@/views/Programs/Volunteers/ext/gridcolumntypes'

export default defineComponent({
  name: 'InviteCell',
  props: { data: { type: Object as PropType<VolunteerListInfo>, required: true } },
  components: {},
  setup(props) {
    const statusText = computed(() => {
      switch (props.data.invitationStatus) {
        case InvitationStatusEnum.ACCEPTED:
          return 'accepted'
        case InvitationStatusEnum.EMPTY:
          return ''
        case InvitationStatusEnum.EXPIRED:
          return 'expired'
        case InvitationStatusEnum.SENT:
          return 'sent'
      }
      return ''
    })

    return { statusText }
  },
})
