

























































import { defineComponent, ref, computed, watch, PropType } from '@vue/composition-api'
import { getProps, useModalValue } from '@/composables/ModalValue'

import VeeValidateForm from '@/elements/VeeValidateForm.vue'

import { VolunteerInfo } from '@/views/Programs/Volunteers/ext/gridcolumntypes'
import PhoneInputMask from '@/elements/PhoneInputMask.vue'
import { IndividualPhone } from '@/GeneratedTypes/IndividualPhone'
import { getEmptyPhone, PhoneTypeEnum } from '@/lib/support/models/IndividualPhone/data'
import Modal from '@/components/Modal.vue'

export enum ResendInvitePayloadMedium {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export interface ResendInvitePayload {
  phoneNumber: string
  medium: ResendInvitePayloadMedium
}

export default defineComponent({
  name: 'InvitationResendModal',
  components: {
    PhoneInputMask,
    VeeValidateForm,
    Modal,
  },
  props: {
    volunteer: { type: Object as PropType<VolunteerInfo | null> },
    ...getProps(),
  },
  setup(props, ctx) {
    const { isModalOpen, closeModal } = useModalValue(props, ctx)
    const internalPhone = ref<IndividualPhone>(getEmptyPhone())

    const email = computed(() => props.volunteer?.emailAddresses?.find(() => true)?.emailAddress || '')

    function clickedResendEmail() {
      doResend(ResendInvitePayloadMedium.EMAIL)
    }

    function clickedResendText() {
      doResend(ResendInvitePayloadMedium.SMS)
    }

    function doResend(medium: ResendInvitePayloadMedium) {
      closeModal()
      ctx.emit('resend', {
        medium,
        phoneNumber: internalPhone.value.phoneNumber,
      } as ResendInvitePayload)
    }

    function cancel() {
      closeModal()
    }

    watch(
      () => props.volunteer,
      () => {
        const phone = props.volunteer?.phoneNumbers?.find(
          (p) => !!p.phoneNumber && p.typePhoneID === PhoneTypeEnum.MOBILE
        )

        if (phone?.phoneNumber) {
          internalPhone.value.phoneNumber = phone.phoneNumber
        } else {
          internalPhone.value.phoneNumber = '' //clear this out or the last volunteer's phone number will show.
        }
      },
      { immediate: true }
    )

    return {
      isModalOpen,
      email,
      clickedResendEmail,
      internalPhone,
      clickedResendText,
      cancel,
    }
  },
})
