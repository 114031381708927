interface CoachAssistantLink {
  id: number
  coach1IndividualID: number
  coach2IndividualID: number
  pendingIndividualID: number
  pendingFirstName: string | null
  pendingLastName: string | null
}
const empty = (): CoachAssistantLink => {
  return {
    id: 0,
    coach1IndividualID: 0,
    coach2IndividualID: 0,
    pendingIndividualID: 0,
    pendingFirstName: '',
    pendingLastName: '',
  }
}

export { empty, CoachAssistantLink }
