/**
 * Grab the first mobile phone info on a contact
 * @param contact
 */
import { VolunteerListInfo } from '@/views/Programs/Volunteers/ext/gridcolumntypes'
import { IndividualPhoneInfo } from '@/GeneratedTypes/ListInfo/IndividualPhoneInfo'
import { getEmptyIndividualPhoneInfo } from '@/lib/support/models/ListInfo/IndividualPhoneInfo/data'
import { PhoneTypeEnum } from '../../IndividualPhone/data'

/***
 * Grab first mobile or a blank phone.
 * @param contact
 */
export function getFirstPhoneInfo(contact: VolunteerListInfo): IndividualPhoneInfo {
  return (
    contact?.phoneNumbers?.filter((x) => x.typePhoneID === PhoneTypeEnum.MOBILE)[0] ||
    getEmptyIndividualPhoneInfo()
  )
}
