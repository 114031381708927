
































import { defineComponent, computed, ref, watch, PropType } from '@vue/composition-api'

import { volunteerSearchHeaders } from '@/lib/components/CoachSelectionModal/VolunteerSelectionGridColumns'
import volunteersClient from '@/clients/volunteersClient'
import { VolunteerSearchInfo } from '@/GeneratedTypes/ListInfo/VolunteerSearchInfo'
import TextInput from '@/elements/TextInput.vue'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { RolesEnum } from '@/lib/support/models/LeagueVolunteer/data'
import SearchModal from '@/elements/SearchModal.vue'

enum SearchChoice {
  EMAIL,
  PHONE,
}

export default defineComponent({
  name: 'VolunteerEmailPhoneSearch',
  components: {
    SearchModal,
    TextInput,
    RadioGroupInput,
  },
  props: {
    autoSearch: { required: false, type: String, default: '' },
    league: { required: true, type: String, default: '' },
    visible: { required: false, type: Boolean, default: false },
    roles: { type: Array as PropType<RolesEnum[] | number[]>, default: () => [0], required: true },
    title: { required: false, type: String, default: 'Coaches' },
  },
  setup(props, ctx) {
    const showModal = ref<boolean>(props.visible)

    watch(
      () => props.visible,
      () => {
        showModal.value = props.visible
      }
    )

    watch(
      () => showModal.value,
      (b: boolean) => {
        if (b) {
          ctx.emit('open')
          return
        }

        ctx.emit('close')
        return
      }
    )

    function cancel() {
      ctx.emit('cancel')
    }

    async function search() {
      searchActive.value = true
      const criteria = {
        leagueID: props.league,
        orSearch: false,
        role: props.roles,
        historyLimit: null,
        includePastLeagues: false,
        useStartsWith: false,
        ...(choice.value == SearchChoice.EMAIL ? { email: term.value } : { phone: term.value }),
      }
      try {
        const result = await volunteersClient.search(criteria)
        searchData.value = result.map((v) => {
          return {
            ...v,
            name: `${v.firstName} ${v.lastName}`,
          }
        })
      } finally {
        searchActive.value = false
      }
      if (searchData.value.length) {
        showModal.value = true
      }
    }

    function searchSelected(p: VolunteerSearchInfo) {
      ctx.emit('select', { individualID: p.individualID, leagueID: p.upwardLeagueID })
    }

    const searchData = ref<VolunteerSearchInfo[]>([])
    const searchActive = ref(false)

    const choice = ref(SearchChoice.EMAIL)
    const term = ref('')

    const choiceName = computed(() => (choice.value ? 'Phone Numbers' : 'Emails'))

    function clear() {
      term.value = ''
    }

    return {
      volunteerSearchHeaders,
      showModal,
      searchActive,
      searchData,
      searchSelected,
      cancel,
      choice,
      term,
      clear,
      search,
      choiceName,
    }
  },
})
