import { CoachProgram } from '@/GeneratedTypes/CoachProgram'

export function getEmptyCoachProgram(seed?: CoachProgramSeed): CoachProgram {
  return {
    typeProgramID: seed?.typeProgramID || '',
    products: [],
    assistantCoachPreferred: false,
    gendersAndGrades: [],
    assistantCoachLink: null,
  }
}

export class CoachProgramSeed {
  typeProgramID = ''
}

export function seedWithProgramID(programId: string) {
  const c = new CoachProgramSeed()
  c.typeProgramID = programId
  return c
}
