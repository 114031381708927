









import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import InputLabel from '@/elements/InputLabel.vue'
import uuid from 'uuid'
import { cloneDeep } from 'lodash'
import store from '@/store'

export default defineComponent({
  name: 'VolunteerRoleAllowOnline',
  components: {
    InputLabel,
  },
  props: {
    upwardLeagueID: { type: String, required: true },
    routeType: { type: String, required: true },
    roleID: { type: Number, required: true },
    allowOPR: { type: Boolean, required: true },
    value: { type: Boolean, required: true },
  },
  setup(props) {
    const storeLeague = computed(() => store.getters.leagues.currentItem)
    const storeCamp = computed(() => store.getters.camps.currentItem)

    const getText = computed(() =>
      props.allowOPR ? 'Show on participant registration' : 'Show on volunteer application'
    )

    const guid = ref(uuid.v4())

    const internalValue = ref<boolean>(props.value)

    async function handleChange() {
      if (props.routeType == 'league') {
        await store.dispatch.leagues.retrieveAndSetAsCurrent({ id: props.upwardLeagueID })
        if (storeLeague.value) {
          const myLeague = cloneDeep(storeLeague.value)
          const changeRole = myLeague.roles?.find((x) => x.id == props.roleID)
          if (changeRole) {
            props.allowOPR
              ? (changeRole.allowInOPR = internalValue.value)
              : (changeRole.allowInOVR = internalValue.value)
            await store.dispatch.leagues.update({ item: myLeague })
          }
        }
      }
      if (props.routeType == 'camp') {
        await store.dispatch.camps.retrieveAndSetAsCurrent({ id: props.upwardLeagueID })
        if (storeCamp.value) {
          const myCamp = cloneDeep(storeCamp.value)
          const changeRole = myCamp.roles?.find((x) => x.id == props.roleID)
          if (changeRole) {
            props.allowOPR
              ? (changeRole.allowInOPR = internalValue.value)
              : (changeRole.allowInOVR = internalValue.value)
            store.dispatch.camps.update({ item: myCamp })
          }
        }
      }
    }

    watch(
      () => props.value,
      () => {
        internalValue.value = props.value
      }
    )

    const elementId = computed(() => `checkbox-${guid.value}`)

    return {
      internalValue,
      elementId,
      handleChange,
      getText,
    }
  },
})
