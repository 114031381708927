import { IndividualEmailInfo } from '@/GeneratedTypes/ListInfo/IndividualEmailInfo'
import { humanEmailToEmailID } from '@/lib/support/models/IndividualEmail/data'

export function getEmptyIndividualEmailInfo(): IndividualEmailInfo {
  return {
    emailAddress: '',
    valid: 'true',
    validityReason: '',
    priority: 1,
    typeEmailID: humanEmailToEmailID('home'),
    emailID: 1,
    individualID: 0,
  }
}
